import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { useTranslation } from "react-i18next";

export default function useIsDebate() {
  const { t } = useTranslation();

  let {
    contentNode: { newsTypes: { nodes: newsTypes } = {} },
  } = usePageContext();

  const isDebate = newsTypes?.find((type) => type.name == t("debateLabel"));

  return isDebate;
}
