import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor";

import getCustomSiteMetaData from "./customSiteMeta";
import { fallbackImage } from "./fallbackImage";
import { getSiteMetaData } from "./siteMeta";

export default function useOpenGraph(initialSeo) {
  let {
    contentNode: {
      contentType: { node: { name: contentTypeName } = {} } = {},
      openGraph,
      content,
      description,
      featuredImage,
      uri,
    } = {},
  } = usePageContext();
  const siteMeta = getSiteMetaData();
  const { customSiteMeta } = getCustomSiteMetaData();
  const { processPageContent, stripHTML } = useHTMLProcessor();

  if (contentTypeName) {
    const processedContent = processPageContent({ content });

    if (!description) {
      if (processedContent.preamble) {
        description = processedContent.preamble;
      } else {
        description = stripHTML(content).slice(0, 300);
      }
    }

    return {
      metaTitle: openGraph.title ? openGraph.title : initialSeo.title,
      metaDescription: openGraph.description
        ? openGraph.description
        : customSiteMeta.description
        ? customSiteMeta.description
        : description,
      metaImage: openGraph.image
        ? openGraph.image
        : featuredImage?.node
        ? featuredImage?.node
        : customSiteMeta.image
        ? customSiteMeta.image
        : fallbackImage(),
      metaUrl: siteMeta.siteUrl + uri,
    };
  }

  return {
    metaTitle: customSiteMeta.title ? customSiteMeta.title : initialSeo.title,
    metaDescription: customSiteMeta.description
      ? customSiteMeta.description
      : initialSeo.description,
    metaImage: customSiteMeta.image ? customSiteMeta.image : fallbackImage(),
    metaUrl: siteMeta.siteUrl,
  };
}
