// extracted by mini-css-extract-plugin
export var arrow = "GalleryModule-module--arrow--9u2w-";
export var arrowDisabled = "GalleryModule-module--arrowDisabled--D2Xv+";
export var arrowIcon = "GalleryModule-module--arrowIcon--UeVmK";
export var arrowNext = "GalleryModule-module--arrowNext---u8w7";
export var arrowPrev = "GalleryModule-module--arrowPrev--joUzk";
export var autoPlayControlIcon = "GalleryModule-module--autoPlayControlIcon--B71r2";
export var autoplayControl = "GalleryModule-module--autoplayControl--y7hbd";
export var autoplayControlWithCaption = "GalleryModule-module--autoplayControlWithCaption--S3Z1V";
export var component = "GalleryModule-module--component--jyVjw";
export var dots = "GalleryModule-module--dots--TSZsT";
export var focusVisible = "GalleryModule-module--focus-visible--r8rbG";
export var imageCaption = "GalleryModule-module--imageCaption---1UiF";
export var inner = "GalleryModule-module--inner--FTJDm";
export var isCompact = "GalleryModule-module--isCompact--Wuztp";