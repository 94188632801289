import { H } from "@jfrk/react-heading-levels";
import { Link, Icon } from "@whitespace/components";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./ArticleSidebar.module.css";

ArticleSidebar.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  children: PropTypes.node,
};

export default function ArticleSidebar({
  styles = defaultStyles,
  className,
  children,
  ...restProps
}) {
  let {
    contentNode: {
      content: contentHTML,
      contentMedia,
      blocksJSON,
      uri: link,
      title,
    },
  } = usePageContext();

  const { t } = useTranslation();

  const [copyState, setCopyState] = useState({
    value: "",
    copied: false,
  });

  const [displayCopyAlert, setDisplayCopyAlert] = useState(false);

  const pageLink = `${process.env.GATSBY_SITE_URL}${link}`;

  const sharingLinks = [
    {
      icon: "twitter",
      link: `https://twitter.com/intent/tweet?url=${pageLink}&text=${title}`,
      label: "Twitter",
    },
    {
      icon: "facebook",
      link: `https://www.facebook.com/sharer/sharer.php?u=${pageLink}`,
      label: "Facebook",
    },
    {
      icon: "linkedin",
      link: `https://www.linkedin.com/shareArticle?mini=true&url=${pageLink}`,
      label: "LinkedIn",
    },
  ];

  useEffect(() => {
    let timeout = null;

    if (copyState.copied) {
      timeout = setTimeout(() => setDisplayCopyAlert(false), 2000);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [copyState]);

  return (
    <aside className={clsx(styles.component, className)}>
      <div className={clsx(styles.share)}>
        <H className={clsx(styles.title, "h3")}>{t("shareOnLabel")}</H>
        {displayCopyAlert && (
          <p className={styles.alert}>{t("linkCopiedLabel")}</p>
        )}
        <ul className={clsx(styles.shareLinks)}>
          {sharingLinks.map(({ icon, link, label }, index) => {
            return (
              <li className={clsx(styles.shareLinkListItem)} key={index}>
                <Link
                  to={link}
                  className={clsx(styles.shareLink, styles.hideExternal)}
                  target="_blank"
                  noreferrer
                  noopener
                >
                  <Icon name={icon} />
                  {label}
                </Link>
              </li>
            );
          })}
          <li className={clsx(styles.shareLinkListItem)}>
            <CopyToClipboard
              text={pageLink}
              onCopy={() => {
                setCopyState({ copied: true });
                setDisplayCopyAlert(true);
              }}
            >
              <Link className={clsx(styles.shareLink)}>
                <Icon name={"link"} />
                {t("copyLinkLabel")}
              </Link>
            </CopyToClipboard>
          </li>
        </ul>
      </div>
      <div>{children}</div>
    </aside>
  );
}
