// extracted by mini-css-extract-plugin
export var component = "Topics-module--component--nlwm8";
export var inner = "Topics-module--inner--MBYEh";
export var item = "Topics-module--item--AYSv0";
export var letter = "Topics-module--letter--9kGcv";
export var link = "Topics-module--link--NqNuw";
export var list = "Topics-module--list--8AbAn";
export var section = "Topics-module--section--ZGLT1";
export var sections = "Topics-module--sections--rOqfJ";
export var toggler = "Topics-module--toggler--6MhWF";
export var togglerIcon = "Topics-module--togglerIcon--hi7V5";