import { css } from "@emotion/react";
import { useComponentSize } from "@whitespace/gatsby-hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import * as defaultStyles from "./TextContent.module.css";

TextContent.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  isCompact: PropTypes.bool,
};

export default function TextContent({
  as: Component = "div",
  children,
  className,
  styles = defaultStyles,
  isCompact = false,
  ...restProps
}) {
  const componentRef = useRef(null);

  let { width: componentWidth } = useComponentSize(componentRef);

  let isComponentCompact = isCompact || componentWidth <= 576;
  return (
    <Component
      className={clsx(
        styles.component,
        isCompact && styles.isCompact,
        className
      )}
      css={css({
        "--text-content-table-wrapper-width": isComponentCompact
          ? `${componentWidth}px`
          : "100%",
      })}
      ref={componentRef}
      {...restProps}
    >
      {children}
    </Component>
  );
}
