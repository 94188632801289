import { H, Section } from "@jfrk/react-heading-levels";
import { Link, RoundIcon } from "@whitespace/components";
import { utilities } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import { useTaxonomies } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./NewsTypes.module.css";

NewsTypes.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function NewsTypes({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t } = useTranslation();
  const taxonomies = useTaxonomies();
  const { terms } = taxonomies.find((taxonomy) => taxonomy.name === "newstype");

  if (!terms) return null;

  return (
    <nav className={clsx(styles.component, className)} {...restProps}>
      <Section>
        <H className={utilities.visuallyHidden}>{t("News types")}</H>
        <ul className={styles.list}>
          {terms.map((term) => (
            <li key={term.id} className={styles.item}>
              <Link to={term.uri} className={styles.link}>
                <span className={styles.name}>{term.name}</span>
                <RoundIcon name={"arrow-right-2"} className={styles.icon} />
              </Link>
            </li>
          ))}
        </ul>
      </Section>
    </nav>
  );
}
