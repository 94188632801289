import { useLocation } from "@gatsbyjs/reach-router";
import { Icon, Button } from "@whitespace/components";
import { usePrevious } from "@whitespace/gatsby-hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import NewsTypes from "../../../components/NewsTypes";
import Topics from "../../../components/Topics";

import * as defaultStyles from "./HeaderFlyOutMenu.module.css";

HeaderFlyOutMenu.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function HeaderFlyOutMenu({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (location !== prevLocation) {
      setExpanded(false);
    }
  }, [location, prevLocation]);

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.documentElement.classList.toggle("overlay--active", expanded);
    }
  }, [expanded]);

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <Button
        type="button"
        onClick={() => setExpanded(!expanded)}
        className={styles.toggle}
      >
        {expanded ? (
          <>
            <Icon name="close" /> {t("menuLabelClose")}
          </>
        ) : (
          <>
            <Icon name="menu" /> {t("menuLabel")}
          </>
        )}
      </Button>
      {expanded && (
        <>
          <div className={styles.backdrop} onClick={() => setExpanded(false)} />
          <div className={styles.taxonomies}>
            <NewsTypes />
            <Topics />
          </div>
        </>
      )}
    </div>
  );
}
