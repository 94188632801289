// extracted by mini-css-extract-plugin
export var background = "ModularityArea-module--background--vnrjw";
export var col3 = "ModularityArea-module--col3--lYv3Q";
export var col4 = "ModularityArea-module--col4--8Vel1";
export var col6 = "ModularityArea-module--col6--KXbSA";
export var col8 = "ModularityArea-module--col8--hD64m";
export var col9 = "ModularityArea-module--col9--Tx5Ys";
export var component = "ModularityArea-module--component--rNASV";
export var full = "ModularityArea-module--full--7bcIm";
export var group = "ModularityArea-module--group--b-M8s";
export var inherit = "ModularityArea-module--inherit--c1w9+";
export var item = "ModularityArea-module--item--7Z8Eq";
export var rightSidebar = "ModularityArea-module--rightSidebar--EzHvx";