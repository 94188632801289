import { useLocation } from "@gatsbyjs/reach-router";
import { Button, Icon } from "@whitespace/components";
import { usePrevious } from "@whitespace/gatsby-hooks";
import clsx from "clsx";
import { Formik, Form, Field } from "formik";
import { navigate } from "gatsby";
import * as layout from "gatsby-theme-vansterpartiet/src/@whitespace/gatsby-theme-wordpress-basic/foundation/layout.module.css";
import useRect from "gatsby-theme-vansterpartiet/src/hooks/useRect";
import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import * as defaultStyles from "./HeaderSearchDropdown.module.css";

const schema = yup.object().shape({
  query: yup.string().required(),
});

HeaderSearchDropdown.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function HeaderSearchDropdown({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const [expanded, setExpanded] = useState(false);

  const componentRef = useRef(null);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const { top: dropdownTop } = useRect(dropdownRef);

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setExpanded(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      setExpanded(false);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    navigate(`/search?query=${values?.query}`);
    resetForm();
  };

  useEffect(() => {
    if (location !== prevLocation) {
      setExpanded(false);
    }
  }, [location, prevLocation]);

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.documentElement.classList.toggle("overlay--active", expanded);
    }
    if (expanded) {
      inputRef?.current.focus();
      window.addEventListener("keydown", handleKeyDown, true);
      document.addEventListener("click", handleClickOutside, true);
    }
    return () => {
      window.removeEventListener("keydown", handleKeyDown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [expanded]);

  return (
    <div
      className={clsx(styles.component, className)}
      {...restProps}
      ref={componentRef}
    >
      <Button
        aria-expanded={expanded}
        aria-controls="search-dropdown"
        className={clsx(styles.toggler, className)}
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? (
          <>
            <Icon name="close" />{" "}
            <span className={styles.label}>{t("searchButtonLabelClose")}</span>
          </>
        ) : (
          <>
            <Icon name="search" className={styles.icon} />{" "}
            <span className={styles.label}>{t("searchButtonLabel")}</span>
          </>
        )}
      </Button>
      {expanded && (
        <div
          className={styles.backdrop}
          style={{ top: `${dropdownTop}px` }}
          onClick={() => setExpanded(false)}
        />
      )}
      <div
        ref={dropdownRef}
        id="search-dropdown"
        hidden={!expanded}
        className={clsx(layout.componentWrapperPadding, styles.dropdown)}
      >
        <Formik
          validationSchema={schema}
          initialValues={{
            query: "",
          }}
          onSubmit={handleSubmit}
          className={styles.form}
        >
          <Form className={styles.form} action="/search" method="get">
            <div className={styles.inputWrapper}>
              <Field
                type="search"
                name="query"
                placeholder={t("searchPlaceholderText")}
                autoComplete={false}
                innerRef={inputRef}
                className={styles.input}
              />
              <Button
                aria-label={t("Search")}
                className={styles.searchIcon}
                type="submit"
              >
                <Icon name="search" />
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}
