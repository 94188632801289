// extracted by mini-css-extract-plugin
export var bodyWrapper = "ContactCard-module--bodyWrapper--iF1QZ";
export var column = "ContactCard-module--column--xn208";
export var component = "ContactCard-module--component--l14mP";
export var contactInfo = "ContactCard-module--contactInfo--8g4vn";
export var content = "ContactCard-module--content--jMM2L";
export var image = "ContactCard-module--image--xyHpq";
export var imageWrapper = "ContactCard-module--imageWrapper--eUU7v";
export var isCompact = "ContactCard-module--isCompact--EWuUH";
export var jobTitle = "ContactCard-module--jobTitle--L4tRp";
export var label = "ContactCard-module--label--MDd26";
export var row = "ContactCard-module--row--AQaph";
export var socialMedia = "ContactCard-module--socialMedia--KB2Kw";
export var socialMediaItem = "ContactCard-module--socialMediaItem--FfeGe";
export var text = "ContactCard-module--text--0GGIv";
export var title = "ContactCard-module--title--YXTh5";