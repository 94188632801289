import { Link, RoundIcon, withComponentDefaults } from "@whitespace/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import useArchiveLink from "../../../hooks/useArchiveLink";

import * as defaultStyles from "./PostsModuleHeader.module.css";

PostsModuleHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  components: PropTypes.objectOf(PropTypes.elementType),
  icon: PropTypes.object,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default withComponentDefaults(PostsModuleHeader, "postsModuleHeader");

function PostsModuleHeader({
  children,
  className,
  components: { Icon = RoundIcon } = { Icon: RoundIcon },
  icon = { name: "arrow-right-2" },
  styles = defaultStyles,
  ...restProps
}) {
  const {
    archiveLinkLabel,
    archiveLinkUri,
    archiveLinkTarget,
    showArchiveLink,
  } = useArchiveLink();

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      {children}
      {!!showArchiveLink && (
        <Link
          className={styles.link}
          to={archiveLinkUri}
          target={archiveLinkTarget}
        >
          {archiveLinkLabel}
          {!!icon && <Icon className={styles.linkIcon} {...icon} />}
        </Link>
      )}
    </div>
  );
}
