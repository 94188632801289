import { H, Section } from "@jfrk/react-heading-levels";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import modularityModuleContext from "@municipio/gatsby-theme-basic/src/modularityModuleContext";
import { Link, Button, RoundIcon } from "@whitespace/components";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import Image from "@whitespace/gatsby-theme-wordpress-basic/src/components/Image";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import { ConditionalWrapper } from "../../../../utils";

import * as defaultStyles from "./HeroModule.module.css";

HeroModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  icon: PropTypes.object,
  title: PropTypes.any,
  module: PropTypes.any,
  children: PropTypes.node,
  components: PropTypes.objectOf(PropTypes.elementType),
};

export default function HeroModule({
  styles = defaultStyles,
  className,
  title,
  components: { Icon = RoundIcon } = { Icon: RoundIcon },
  icon = { name: "arrow-right-2" },
  module: {
    modHeroOptions: {
      text,
      image,
      imageAlignment: reversed,
      useHeadingAsH1,
      links,
    } = {},
  },
  children,
  ...restProps
}) {
  const { background } = useContext(modularityModuleContext);

  return (
    <ModuleWrapper
      as={"div"}
      styles={styles}
      className={clsx(
        className,
        !!reversed && styles.reversed,
        !!background && styles.hasBackground
      )}
      {...restProps}
    >
      {!!image && (
        <Image
          className={clsx(styles.image)}
          {...image}
          aspectRatio={16 / 9}
          caption={""}
        />
      )}

      <ConditionalWrapper
        condition={!useHeadingAsH1}
        wrapper={(children) => <Section>{children}</Section>}
      >
        <div className={clsx(styles.content)}>
          {title && <H className={clsx(styles.title, "h1")}>{title}</H>}

          <HTML>{text}</HTML>

          {!!links && (
            <div className={clsx(styles.links)}>
              {links.map(({ display, link }, index) => {
                return (
                  <>
                    {display == "cta" && (
                      <div
                        className={clsx(styles.cta, className)}
                        {...restProps}
                      >
                        <Link
                          className={styles.link}
                          to={link.url}
                          target={link.target}
                        >
                          {link.title}
                          {!!icon && (
                            <Icon className={styles.linkIcon} {...icon} />
                          )}
                        </Link>
                      </div>
                    )}
                    {display == "button" && (
                      <Button to={link.url} target={link.target}>
                        {link.title}
                      </Button>
                    )}
                    {display == "link" && (
                      <Link
                        to={link.url}
                        target={link.target}
                        className={clsx(styles.link)}
                        key={index}
                      >
                        {link.title}
                      </Link>
                    )}
                  </>
                );
              })}
            </div>
          )}

          {children}
        </div>
      </ConditionalWrapper>
    </ModuleWrapper>
  );
}
