// extracted by mini-css-extract-plugin
export var articleBody = "SplitArticleLayout-module--articleBody--4oggx";
export var articleContentContainer = "SplitArticleLayout-module--articleContentContainer--ewhQa";
export var articleRelatedPosts = "SplitArticleLayout-module--articleRelatedPosts--TNuPV";
export var component = "SplitArticleLayout-module--component--opQ4k";
export var content = "SplitArticleLayout-module--content--rb3t5";
export var contentAreaBottom = "SplitArticleLayout-module--contentAreaBottom--v89DL";
export var footer = "SplitArticleLayout-module--footer--JRcwe";
export var header = "SplitArticleLayout-module--header--sz-NR";
export var publishedDate = "SplitArticleLayout-module--publishedDate--DV3Ld";
export var sliderArea = "SplitArticleLayout-module--sliderArea--gP7lp";
export var wrapper = "SplitArticleLayout-module--wrapper--Kt18T";