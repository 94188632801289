import { Icon } from "@whitespace/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./ContentNodeBroadcastMeta.module.css";

ContentNodeBroadcastMeta.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.objectOf(PropTypes.string),
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function ContentNodeBroadcastMeta({
  type,
  styles = defaultStyles,
  className,
  children,
  ...restProps
}) {
  const { t } = useTranslation();

  const iconLabel = {
    video: "video-small",
    sound: "headphones-small",
  };

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <div className={clsx(styles.media)}>
        {Object.keys(type).map(
          (key, index) =>
            type[key] && (
              <span className={clsx(styles.type)} key={index}>
                <Icon name={iconLabel[key]} /> {t(`${key}Label`)}
              </span>
            )
        )}
      </div>
      {children}
    </div>
  );
}
