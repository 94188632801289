// extracted by mini-css-extract-plugin
export var broadcast = "PostsModuleMixedLayout-module--broadcast--WXa1I";
export var component = "PostsModuleMixedLayout-module--component--8VE53";
export var eventList = "PostsModuleMixedLayout-module--eventList--YE+m3";
export var filterForm = "PostsModuleMixedLayout-module--filterForm--EMG9B";
export var item = "PostsModuleMixedLayout-module--item--Gkhee";
export var list = "PostsModuleMixedLayout-module--list--9lsx-";
export var noRule = "PostsModuleMixedLayout-module--noRule--12ARc";
export var secondaryItem = "PostsModuleMixedLayout-module--secondaryItem--RXJLG";
export var secondaryList = "PostsModuleMixedLayout-module--secondaryList--dMKHQ";
export var secondaryListColumn = "PostsModuleMixedLayout-module--secondaryListColumn--3VLpj";