import { Link } from "@whitespace/components";
import Time from "@whitespace/gatsby-theme-wordpress-basic/src/components/Time";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./ArticleMeta.module.css";

ArticleMeta.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function ArticleMeta({ styles = defaultStyles }) {
  const { t } = useTranslation();
  let {
    contentNode: { dateGmt, modifiedGmt, author, authors },
  } = usePageContext();

  return (
    <>
      {modifiedGmt && (
        <div className={styles.row}>
          <span className={styles.label}>{t("updatedAt")}: </span>
          <Time
            date={modifiedGmt}
            format={{
              day: "numeric",
              month: "long",
              year: "numeric",
            }}
          />
        </div>
      )}
      {!!author?.node?.name && (
        <div className={styles.row}>
          <span className={styles.label}>{t("managedBy")}: </span>
          {author.node.name}
        </div>
      )}

      {!!authors?.nodes.length && (
        <div className={styles.row}>
          <span className={styles.label}>{t("author")}: </span>
          <div className={clsx(styles.linkWrapper)}>
            {authors.nodes.map((author, index) => (
              <Link
                to={author.uri}
                key={index}
                className={clsx(
                  index !== authors.nodes.length - 1 && styles.join
                )}
              >
                {author.name}
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
