import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import * as defaultStyles from "./InfoboxModule.module.css";

InfoboxModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  module: PropTypes.shape({
    modInfoboxOptions: PropTypes.shape({
      text: PropTypes.string,
    }),
  }),
};

export default function InfoboxModule({
  styles = defaultStyles,
  module: { modInfoboxOptions: { text } = {} },
  ...restProps
}) {
  const componentRef = useRef(null);

  return (
    <ModuleWrapper
      {...restProps}
      className={clsx(styles.box)}
      styles={styles}
      ref={componentRef}
    >
      <div className={styles.text}>
        <HTML className={styles.text}>{text}</HTML>
      </div>
    </ModuleWrapper>
  );
}
