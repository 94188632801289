import Image from "@whitespace/gatsby-theme-wordpress-basic/src/components/Image";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./ArticleFeaturedImage.module.css";

ArticleFeaturedImage.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function ArticleFeaturedImage({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  let {
    contentNode: { featuredImage, shows: { nodes: shows = [] } = {} },
    // isPreview,
  } = usePageContext();
  const { processContent } = useHTMLProcessor();

  if (shows.length) {
    featuredImage = shows[0].featuredImage;
  }

  featuredImage = !!(featuredImage && featuredImage.node) && {
    ...featuredImage.node,
    caption:
      featuredImage.node.caption && processContent(featuredImage.node.caption),
  };

  if (!featuredImage) {
    return null;
  }
  return (
    <div className={clsx(styles.component, "articleImage", className)}>
      <Image {...featuredImage} {...restProps} />
    </div>
  );
}
