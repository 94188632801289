export default function useEventTime({ startDate, endDate }) {
  if (withoutTime(startDate).getTime() === withoutTime(endDate).getTime()) {
    // If startDate and endDate is the same date but the hours are different (we don't compare minutes… yet)
    if (new Date(startDate).getHours() === new Date(startDate).getHours()) {
      return {
        start: formatter(startDate, "time"),
        end: formatter(endDate, "time"),
      };
    }

    // If startDate and endDate is the same date
    return {
      start: formatter(startDate, "time"),
      end: null,
    };
  }

  return {
    start: formatter(startDate, "shortdate"),
    end: formatter(endDate, "shortdate"),
  };
}

export const withoutTime = (date) => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  return d;
};

export const formatter = (date, format) => {
  switch (format) {
    case "date":
      return {
        date,
        format: {
          month: "long",
          day: "numeric",
          year: "numeric",
        },
      };
    case "shortdate":
      return {
        date,
        format: {
          month: "short",
          day: "numeric",
        },
      };
    case "time":
      return {
        date,
        format: {
          hour: "numeric",
          minute: "numeric",
        },
      };
    default:
      return {
        date,
        format: {
          month: "long",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        },
      };
  }
};
