import { H, Section } from "@jfrk/react-heading-levels";
import { Icon, Link } from "@whitespace/components";
import { useComponentSize } from "@whitespace/gatsby-hooks";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import Image from "@whitespace/gatsby-theme-wordpress-basic/src/components/Image";
import clsx from "clsx";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./ContactCard.module.css";

ContactCard.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  contact: PropTypes.shape({
    address: PropTypes.string,
    administrationUnit: PropTypes.node,
    email: PropTypes.string,
    firstName: PropTypes.string,
    image: PropTypes.any,
    lastName: PropTypes.string,
    openingHours: PropTypes.string,
    other: PropTypes.string,
    phoneNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
      })
    ),
    socialMedia: PropTypes.arrayOf(
      PropTypes.shape({
        media: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
    visitingAddress: PropTypes.string,
    workTitle: PropTypes.string,
  }),
};

const SOCIAL_MEDIA_NAMES = {
  facebook: "Facebook",
  linkedin: "LinkedIn",
  twitter: "Twitter",
  instagram: "Instagram",
};

export default function ContactCard({
  className,
  contact,
  styles = defaultStyles,
  ...restProps
}) {
  const {
    address,
    administrationUnit,
    email,
    firstName,
    image,
    lastName,
    openingHours,
    other,
    phoneNumbers,
    socialMedia,
    visitingAddress,
    workTitle,
  } = contact;

  let componentRef = useRef(null);

  let { width: componentWidth } = useComponentSize(componentRef);
  const { t } = useTranslation();

  const isCompact = componentWidth <= 768;

  return (
    <address
      itemScope=""
      itemType="http://schema.org/Person"
      className={clsx(
        styles.component,
        isCompact && styles.isCompact,
        className
      )}
      ref={componentRef}
      {...restProps}
    >
      {image && (
        <div className={clsx(styles.imageWrapper)}>
          <Image
            className={clsx(styles.image)}
            {...image}
            aspectRatio={16 / 9}
            caption={""}
            alt={lastName ? `${firstName} ${lastName}` : firstName}
          />
        </div>
      )}
      <div className={clsx(styles.bodyWrapper)}>
        <div className={clsx(styles.header, styles.column)}>
          <H itemProp="name" className={clsx(styles.title)}>
            {lastName ? `${firstName} ${lastName}` : firstName}
          </H>
          {workTitle && (
            <p itemProp="jobTitle" className={clsx(styles.jobTitle)}>
              {workTitle}
            </p>
          )}
          {administrationUnit && (
            <p itemProp="jobTitle" className={clsx(styles.jobTitle)}>
              <i>{administrationUnit}</i>
            </p>
          )}
        </div>
        <Section>
          <div className={clsx(styles.content)}>
            <div className={clsx(styles.column)}>
              {email && (
                <div className={clsx(styles.row)}>
                  <span className={clsx(styles.label)}>{t("eMailLabel")}:</span>
                  <Link href={`mailto:${email}`} itemProp="email">
                    <span>{email}</span>
                  </Link>
                </div>
              )}
              {phoneNumbers &&
                phoneNumbers.map(({ type, number }, index) => {
                  if (!number) {
                    return null;
                  }

                  let label =
                    type == "phone"
                      ? t("phoneNumberLabel")
                      : t("mobileNumberLabel");
                  return (
                    <div key={index} className={clsx(styles.row)}>
                      <span className={clsx(styles.label)}>{label}:</span>
                      <div itemProp="telephone">
                        <span>{number}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className={clsx(styles.column, styles.socialMedia)}>
              {socialMedia &&
                socialMedia.map(
                  ({ media, url }, index) =>
                    url && (
                      <Link
                        href={url}
                        key={index}
                        className={clsx(styles.socialMediaItem)}
                      >
                        <Icon name={`${media}`} />
                        <span>{SOCIAL_MEDIA_NAMES[media]}</span>
                      </Link>
                    )
                )}
            </div>
            {address && (
              <div className={clsx(styles.column)}>
                <H className={clsx(styles.title, "h6")}>{t("addressLabel")}</H>
                <div className={clsx(styles.text)}>
                  <HTML>{address}</HTML>
                </div>
              </div>
            )}

            {visitingAddress && (
              <div className={clsx(styles.column)}>
                <H className={clsx(styles.title, "h6")}>
                  {t("visitingAddressLabel")}
                </H>
                <div className={clsx(styles.text)}>
                  <HTML>{visitingAddress}</HTML>
                </div>
              </div>
            )}
            {openingHours && (
              <div className={clsx(styles.column)}>
                <H className={clsx(styles.title, "h6")}>
                  {t("openingHoursLabel")}
                </H>
                <div className={clsx(styles.text)}>
                  <HTML>{openingHours}</HTML>
                </div>
              </div>
            )}
            {other && (
              <div className={clsx(styles.column)}>
                <div className={clsx(styles.text)}>
                  <HTML>{other}</HTML>
                </div>
              </div>
            )}
          </div>
        </Section>
      </div>
    </address>
  );
}
