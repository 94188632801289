import { H, Section } from "@jfrk/react-heading-levels";
import IframeModule from "@municipio/gatsby-theme-basic/src/components/modularity-modules/IframeModule";
import { useComponentSize } from "@whitespace/gatsby-hooks";
import { utilities } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player/lazy";

import * as defaultStyles from "./ArticleBroadcastChannel.module.css";

ArticleBroadcastChannel.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function ArticleBroadcastChannel({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const {
    contentNode: {
      media: { captivateFm, facebookVideo, youtubeVideo } = {},
    } = {},
  } = usePageContext();

  const { t } = useTranslation();
  const componentRef = useRef();
  let { width: componentWidth } = useComponentSize(componentRef);

  const channels = {
    captivate:
      captivateFm &&
      ((index) => (
        <IframeModule
          key={index}
          title={() => (
            <H className={utilities.visuallyHidden}>{t("podcastEpLabel")}</H>
          )}
          module={{
            modIframeOptions: {
              iframeHeight: 200,
              iframeUrl: captivateFm,
            },
          }}
        />
      )),
    facebook: facebookVideo && {
      title: "facebookVideoLabel",
      url: facebookVideo,
    },
    youtube: youtubeVideo && {
      title: "youtubeVideoLabel",
      url: `https://www.youtube.com/watch?v=${youtubeVideo}`,
    },
  };

  return (
    <div
      className={clsx(styles.component, className)}
      ref={componentRef}
      {...restProps}
    >
      {Object.keys(channels).map((channelKey, index) => {
        const channel = channels[channelKey];
        if (!channel) return null;

        return typeof channel == "function" ? (
          channel(index)
        ) : (
          <div key={index}>
            <H className={utilities.visuallyHidden}>{t(channel.title)}</H>
            <div className={clsx(styles.wrapper, styles[channelKey])}>
              <ReactPlayer
                className={clsx(styles.player)}
                url={channel.url}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
