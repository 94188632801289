// extracted by mini-css-extract-plugin
export var articleBody = "DebateArticleLayout-module--articleBody--OBMp+";
export var articleContentContainer = "DebateArticleLayout-module--articleContentContainer--NUjeB";
export var articleRelatedPosts = "DebateArticleLayout-module--articleRelatedPosts--1WRKj";
export var backDrop = "DebateArticleLayout-module--backDrop--9se35";
export var component = "DebateArticleLayout-module--component--MVK9F";
export var content = "DebateArticleLayout-module--content--f+rQx";
export var contentAreaBottom = "DebateArticleLayout-module--contentAreaBottom--1iYRS";
export var featuredImage = "DebateArticleLayout-module--featuredImage--skk0e";
export var footer = "DebateArticleLayout-module--footer--WvtxJ";
export var header = "DebateArticleLayout-module--header--95qyF";
export var publishedDate = "DebateArticleLayout-module--publishedDate--jXHuG";
export var sliderArea = "DebateArticleLayout-module--sliderArea--EAy5D";
export var title = "DebateArticleLayout-module--title--SIjis";
export var wrapper = "DebateArticleLayout-module--wrapper--5SODx";