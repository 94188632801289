import useTaxonomies from "@municipio/gatsby-theme-basic/src/hooks/useTaxonomies";
import getMostRelevantDate from "@municipio/gatsby-theme-basic/src/utils/getMostRelevantDate";
import React from "react";

function visibleFields(item) {
  return {
    showDate: item?.includes("date"),
    showImage: item?.includes("image"),
    showExcerpt: item?.includes("excerpt"),
  };
}

export default function normalizePostsModuleItems(
  { modPostsDataSource, contentNodes, modPostsDataDisplay },
  { HTML, stripHTML } = {}
) {
  if (!modPostsDataSource?.postsDataSource) {
    return [];
  }
  const { showDate, showImage, showExcerpt } = visibleFields(
    modPostsDataDisplay.postsFields
  );
  switch (modPostsDataSource.postsDataSource) {
    case "input":
      return (modPostsDataSource.data || []).map(
        ({ postContentMedia, ...item }) => {
          let processedContent = (
            <HTML contentMedia={postContentMedia}>{item.postContent}</HTML>
          );
          return {
            ...item,
            title: item.postTitle,
            url: item.link?.url || item.permalink,
            excerpt: showExcerpt && stripHTML(item.postContent),
            content: showExcerpt && processedContent,
          };
        }
      );

    case "rss":
      return (modPostsDataSource.feedData || []).map((item) => {
        let processedContent = <HTML>{item.content}</HTML>;
        return {
          ...item,
          title: item.title,
          url: item.url,
          date: showDate && item.date,
          image:
            showImage && item.imageJSON ? JSON.parse(item.imageJSON) : null,
          excerpt: showExcerpt && stripHTML(item.content),
          content: showExcerpt && processedContent,
        };
      });

    default: {
      let itemsArr = contentNodes?.nodes || [];
      let itemsToSlice =
        modPostsDataSource.postsCount >= 0
          ? modPostsDataSource.postsCount
          : itemsArr.length;

      let items = itemsArr
        .filter(Boolean)
        .slice(0, itemsToSlice)
        .map(({ contentMedia, ...item }) => {
          let processedContent = (
            <HTML contentMedia={contentMedia}>{item.content}</HTML>
          );

          const isBroadcast = item.contentType.node.name == "broadcast";
          let featuredImage = showImage && item.featuredImage?.node;

          const broadcastType = isBroadcast && {
            video: !!(item?.media?.facebookVideo || item?.media?.youtubeVideo),
            sound: !!item?.media?.captivateFm,
          };

          const show = isBroadcast && item.shows?.nodes[0];

          return {
            ...item,
            contentType: item.contentType?.node?.name,
            title: item.title,
            dateGmt: showDate && item.dateGmt,
            date:
              showDate &&
              ((item.archiveDatesGmt &&
                getMostRelevantDate(item.archiveDatesGmt)) ||
                item.dateGmt),
            url: item.uri,
            postType: item.contentType.node.name,
            excerpt:
              showExcerpt &&
              (item.description ? item.description : stripHTML(item.content)),
            image:
              isBroadcast &&
              showImage &&
              broadcastType.sound &&
              !broadcastType.video
                ? show.featuredImage?.node
                : featuredImage,
            content: showExcerpt && processedContent,
            taxonomies: useTaxonomies(
              { ...item.tags?.nodes, ...item.categories?.nodes },
              item.contentType?.node?.name
            ),
            show: show,
            broadcastType: broadcastType,
          };
        });

      return items;
    }
  }
}
