import Footer from "@whitespace/gatsby-theme-wordpress-basic/src/components/Footer";
import Header from "@whitespace/gatsby-theme-wordpress-basic/src/components/Header";
import * as defaultStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/SiteLayout.module.css";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import TaxonomyIndex from "../../../components/TaxonomyIndex";

SiteLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function SiteLayout({
  children,
  className,
  styles = defaultStyles,
}) {
  return (
    <div className={clsx(styles.component, className)}>
      <Header />
      <main className={styles.main} id="main">
        {children}
      </main>
      <TaxonomyIndex />
      <Footer />
    </div>
  );
}
