import {
  Card,
  CardContent,
  CardMedia,
  CardMeta,
  CardTitle,
  Icon,
} from "@whitespace/components";
import { useComponentSize } from "@whitespace/gatsby-hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import CalendarBadge from "../../../../components/CalendarBadge";
import useEventTime from "../../../../hooks/useEventTimeFormatter";

import * as styles from "./WPEventCard.module.css";

WPEventCard.propTypes = {
  className: PropTypes.string,
  contentNode: PropTypes.shape({
    broadcastType: PropTypes.string,
    content: PropTypes.node,
    date: PropTypes.string,
    excerpt: PropTypes.node,
    image: PropTypes.object,
    acfGroupEvent: PropTypes.object,
    location: PropTypes.object,
    postType: PropTypes.string,
    theme: PropTypes.string,
    title: PropTypes.node,
    url: PropTypes.string,
  }),
  dateFormat: PropTypes.objectOf(PropTypes.string),
  // styles: PropTypes.objectOf(PropTypes.string),
};

export default function WPEventCard({
  contentNode,
  dateFormat = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  },
  className,
  ...restProps
}) {
  const {
    date,
    excerpt,
    image,
    title,
    url,
    location: { nodes },
    acfGroupEvent: { bookingLink, occasions = [] },
  } = contentNode;
  let componentRef = useRef(null);

  let { width: componentWidth } = useComponentSize(componentRef);
  const startDate = occasions && useEventTime(occasions[0]);
  const isCompact = componentWidth <= 272;
  const locationName = nodes[0]?.title;

  return (
    <div ref={componentRef}>
      <Card
        link={{ url }}
        className={clsx(
          styles.component,
          isCompact && styles.isCompact,
          className
        )}
        {...restProps}
      >
        <CardContent className={clsx(styles.cardContent)}>
          <CardTitle className={clsx(styles.title)}>{title}</CardTitle>
          {date && (
            <CardMeta>
              <Icon name="map-pin" className={clsx(styles.icon)} />
              <span className={clsx(styles.date)}>{locationName}</span>
            </CardMeta>
          )}

          {excerpt && <p className={clsx(styles.excerpt)}>{excerpt}</p>}
        </CardContent>

        {image && (
          <>
            <CardMedia className={styles.mediaWrapper} image={image} />
            <CalendarBadge
              className={styles.badge}
              date={startDate?.start?.date}
              locale={"sv"}
            />
          </>
        )}
      </Card>
    </div>
  );
}
