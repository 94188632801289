import { useLocation } from "@gatsbyjs/reach-router";
import { H, Section } from "@jfrk/react-heading-levels";
import { Link, Button, Icon } from "@whitespace/components";
import { usePrevious } from "@whitespace/gatsby-hooks";
import { utilities } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import { useTaxonomies } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./Topics.module.css";

Topics.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function Topics({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const taxonomies = useTaxonomies();
  const location = useLocation();
  const prevLocation = usePrevious(location);

  const { terms } = taxonomies.find((taxonomy) => taxonomy.name === "post_tag");

  if (!terms) return null;

  const groupedTerms = terms.reduce((acc, term) => {
    const letter = term.name[0].toUpperCase();
    if (!acc[letter]) {
      acc[letter] = { letter, terms: [term] };
    } else {
      acc[letter].terms.push(term);
    }
    return acc;
  }, {});

  const sections = Object.values(groupedTerms).sort((a, b) =>
    a.letter.localeCompare(b.letter, "sv")
  );

  useEffect(() => {
    if (location !== prevLocation) {
      setExpanded(false);
    }
  }, [location, prevLocation]);

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <Section>
        <H className={utilities.visuallyHidden}>{t("Tags")}</H>
        <div className={styles.sections} hidden={!expanded}>
          <div className={styles.inner}>
            {sections.map((section, index) => (
              <section key={index} className={styles.section}>
                <H className={styles.letter}>
                  <span className={utilities.visuallyHidden}>
                    {t("Topics that starts with")}
                  </span>{" "}
                  {section.letter}
                </H>
                <ul className={styles.list}>
                  {section.terms.map((term) => (
                    <li key={term.id} className={styles.item}>
                      <Link to={term.uri} className={styles.link}>
                        {term.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </section>
            ))}
          </div>
        </div>
      </Section>
      <Button
        className={styles.toggler}
        onClick={() => setExpanded(!expanded)}
        aria-expanded={expanded}
      >
        {t("Topics")}
        <Icon name={"caret-up"} className={styles.togglerIcon} />
      </Button>
    </div>
  );
}
