import ArticleTitle from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTitle";
import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./EventPageHeader.module.css";

EventPageHeader.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  menuName: PropTypes.string,
};

export default function EventPageHeader({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const {
    contentNode: {
      eventCategories,
      location: {
        address: { city, street, zip },
      },
    },
  } = usePageContext();

  const categories = eventCategories?.nodes;

  return (
    <header
      className={clsx(styles.component, layout.component, className)}
      {...restProps}
    >
      {categories && categories.length > 0 && (
        <div className={styles.categories}>
          {categories.map((category, index) => (
            <span key={index} className={styles.category}>
              {category.name}
            </span>
          ))}
        </div>
      )}
      <ArticleTitle className={styles.title} />
      <p className={styles.address}>
        {street && <span>{street}, </span>}
        {city && <span>{city} </span>}
        {zip && <span>{zip}</span>}
      </p>
    </header>
  );
}
