import { useState, useCallback, useLayoutEffect } from "react";

function getRect(el, defaultValues) {
  if (!el) {
    return defaultValues;
  }
  return el.getBoundingClientRect();
}

export default function useRect(
  ref,
  defaultValues = {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  }
) {
  const [rect, setRect] = useState(
    getRect(ref ? ref.current : {}, defaultValues)
  );

  var handleResize = useCallback(() => {
    if (ref.current) {
      setRect(getRect(ref.current));
    }
  }, [ref]);

  useLayoutEffect(
    function () {
      if (!ref.current) {
        return;
      }
      handleResize();
      if (typeof ResizeObserver === "function") {
        var resizeObserver = new ResizeObserver(function () {
          handleResize();
        });
        let el = ref.current;
        resizeObserver.observe(el);
        return () => {
          resizeObserver.disconnect(el);
          resizeObserver = null;
        };
      } else {
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }
    },
    [ref]
  );

  return rect;
}
