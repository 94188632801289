import { SkipTo, PageGrid, PageGridItem } from "@whitespace/components";
import HeaderFlyOutMenu from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderFlyOutMenu";
import HeaderLogo from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderLogo";
import HeaderMainMenu from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderMainMenu";
import clsx from "clsx";
import * as layout from "gatsby-theme-vansterpartiet/src/@whitespace/gatsby-theme-wordpress-basic/foundation/layout.module.css";
import HeaderSearchDropdown from "gatsby-theme-vansterpartiet/src/components/HeaderSearchDropdown";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./Header.module.css";

Header.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function Header({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t } = useTranslation();
  return (
    <header className={clsx(styles.component, className)} {...restProps}>
      <PageGrid>
        <PageGridItem
          className={clsx(styles.inner, layout.componentWrapperPadding)}
        >
          <SkipTo />
          <HeaderLogo linkTo="/" className={styles.logo} />
          <div className={styles.controls}>
            <HeaderSearchDropdown />
            <HeaderFlyOutMenu />
          </div>

          <HeaderMainMenu aria-label={t("mainMenu")} />
        </PageGridItem>
      </PageGrid>
    </header>
  );
}
