import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./CalendarBadge.module.css";

CalendarBadge.propTypes = {
  children: PropTypes.node,
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  locale: PropTypes.string,
  date: PropTypes.instanceOf(Date),
};

export default function CalendarBadge({
  className,
  styles = defaultStyles,
  locale,
  date,
  ...restProps
}) {
  const transformedDate = new Date(date.replace(/-/g, "/"));

  const month = transformedDate.toLocaleDateString(locale, { month: "short" });
  const day = transformedDate.toLocaleDateString(locale, { day: "numeric" });

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <div className={clsx(styles.day)} aria-hidden aria-label="day">
        {day}
      </div>
      <div className={clsx(styles.month)} aria-hidden aria-label="month">
        {month}
      </div>
    </div>
  );
}
