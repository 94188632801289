import { css } from "@emotion/react";
import ModuleController from "@municipio/gatsby-theme-basic/src/components/ModuleController";
import modularityAreaContext from "@municipio/gatsby-theme-basic/src/modularityAreaContext";
import modularityModuleContext from "@municipio/gatsby-theme-basic/src/modularityModuleContext";
import { MaybeFragment } from "@whitespace/components";
import clsx from "clsx";
import { kebabCase } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import useDynamicModules from "../../../hooks/useDynamicModules";

import * as defaultStyles from "./ModularityArea.module.css";
import ModularityGroupWrapper from "./ModularityGroupWrapper";

ModularityArea.propTypes = {
  area: PropTypes.any,
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  rightSidebar: PropTypes.bool,
};

export default function ModularityArea({
  area = {},
  rightSidebar = false,
  className,
  styles = defaultStyles,
  ...restProps
}) {
  let { modules } = area;

  modules = modules?.filter((item) => !!item.module);

  if (!modules?.length) {
    return null;
  }
  let dynamicModules = useDynamicModules(modules, {
    keys: [],
    modules: [],
  });

  // TODO: Wrap in PageGridGroup components based on columnWidth
  return (
    <modularityAreaContext.Provider value={area}>
      {!!dynamicModules.keys && (
        <MaybeFragment
          as="div"
          className={clsx(styles.component, className)}
          {...restProps}
        >
          {dynamicModules.modules.map((group, index) => {
            const items = group.items;
            return (
              <ModularityGroupWrapper
                key={index}
                items={items}
                className={styles.group}
              >
                {items.map(
                  (
                    { hidden, module, background, columnWidth, ...rest },
                    index
                  ) => {
                    if (hidden || !module) {
                      return null;
                    }

                    const col = columnWidth
                      ? columnWidth.replace("grid-md-", "col")
                      : "inherit";

                    return (
                      <modularityModuleContext.Provider
                        value={{
                          hidden,
                          module,
                          background,
                          columnWidth,
                          ...rest,
                        }}
                        key={index}
                      >
                        <div
                          className={clsx(
                            rightSidebar ? styles.rightSidebar : styles.item,
                            styles[col],
                            background && styles.background
                          )}
                          css={css({
                            "--modularity-area-item-background": background
                              ? `var(--brand-color-${kebabCase(background)})`
                              : "",
                          })}
                        >
                          <ModuleController module={module} />
                        </div>
                      </modularityModuleContext.Provider>
                    );
                  }
                )}
              </ModularityGroupWrapper>
            );
          })}
        </MaybeFragment>
      )}
    </modularityAreaContext.Provider>
  );
}
