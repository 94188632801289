import { Icon } from "@whitespace/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./ContentNodeBroadcastMedia.module.css";

ContentNodeBroadcastMedia.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.objectOf(PropTypes.string),
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function ContentNodeBroadcastMedia({
  type,
  children,
  styles = defaultStyles,
  className,
  ...restProps
}) {
  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      {children}
      {type && (
        <Icon
          className={styles.icon}
          name={
            !type.video && type.sound
              ? "headphones"
              : type.video
              ? "play"
              : null
          }
        />
      )}
    </div>
  );
}
