import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";

import useIsDebate from "./useIsDebate";

export default function usePageLayout() {
  let {
    contentNode: {
      contentType: {
        node: { name: contentTypeName },
      },
      content,
    },
  } = usePageContext();

  const isDebate = useIsDebate();

  const layouts = {
    page: content ? "split" : "default",
    post: isDebate ? "debate" : "split",
    broadcast: "split",
  };
  return layouts[contentTypeName] || "default";
}
