import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import * as defaultStyles from "./ModularityGroupWrapper.module.css";

ModularityGroupWrapper.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function ModularityGroupWrapper({
  items,
  children,
  className,
  styles = defaultStyles,
  ...restProps
}) {
  return items.length > 1 ? (
    <div
      className={clsx(styles.component, styles.componentGroup, className)}
      {...restProps}
    >
      {children}
    </div>
  ) : (
    <div className={clsx(styles.component, styles.componentFull, className)}>
      {children}
    </div>
  );
}
