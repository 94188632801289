import {
  Card,
  CardContent,
  CardMedia,
  CardMeta,
  CardTitle,
} from "@whitespace/components";
import { useComponentSize } from "@whitespace/gatsby-hooks";
import {
  TermList,
  Time,
} from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import * as styles from "./ContentNodeCard.module.css";

WPDefaultContentNodeCard.propTypes = {
  className: PropTypes.string,
  contentNode: PropTypes.shape({
    content: PropTypes.node,
    date: PropTypes.string,
    excerpt: PropTypes.node,
    image: PropTypes.object,
    theme: PropTypes.string,
    title: PropTypes.node,
    url: PropTypes.string,
    taxonomies: PropTypes.arrayOf(PropTypes.object),
  }),
  dateFormat: PropTypes.objectOf(PropTypes.string),
  // styles: PropTypes.objectOf(PropTypes.string),
};

export default function WPDefaultContentNodeCard({
  contentNode,
  dateFormat = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  },
  className,
  ...restProps
}) {
  const { date, excerpt, image, title, url, taxonomies } = contentNode;
  let componentRef = useRef(null);

  let { width: componentWidth } = useComponentSize(componentRef);

  const isCompact = componentWidth <= 272;
  return (
    <div ref={componentRef}>
      <Card
        link={{ url }}
        className={clsx(
          styles.component,
          isCompact && styles.isCompact,
          className
        )}
        {...restProps}
      >
        <CardContent>
          <CardTitle className={clsx(styles.title)}>{title}</CardTitle>
          {date && (
            <CardMeta>
              <Time
                capitalize={true}
                className={clsx(styles.date)}
                date={date}
                format={dateFormat}
              />
            </CardMeta>
          )}
          {excerpt && <p className={clsx(styles.excerpt)}>{excerpt}</p>}
          {taxonomies && taxonomies.length > 0 && (
            <TermList taxonomies={taxonomies} />
          )}
        </CardContent>
        {image && <CardMedia image={image} />}
      </Card>
    </div>
  );
}
