import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import VideoIframe from "@municipio/gatsby-theme-basic/src/components/VideoIframe";
import { Icon } from "@whitespace/components";
import { Image } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import urlParser from "js-video-url-parser";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./VideoModule.module.css";

VideoModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  module: PropTypes.shape({
    modVideoOptions: PropTypes.shape({
      // type: PropTypes.string,
      // videoMp4: PropTypes.shape({
      //   mediaItemUrl: PropTypes.string,
      //   mimeType: PropTypes.string,
      // }),
      embedLink: PropTypes.string,
      placeholderImage: PropTypes.objectOf(PropTypes.string),
    }),
  }),
};

export default function VideoModule({
  className,
  module = {},
  styles = defaultStyles,
  title,
  ...restProps
}) {
  const [playVideo, setPlayVideo] = useState(false);
  const { modVideoOptions: { embedLink, placeholderImage } = {} } = module;
  const url = urlParser.create({
    videoInfo: urlParser.parse(embedLink),
    format: "embed",
    params: {
      autoplay: 1,
    },
  });

  const { t } = useTranslation();

  return (
    <ModuleWrapper
      title={title}
      {...restProps}
      className={clsx(styles.component, className)}
    >
      {!playVideo && (
        <div
          className={clsx(styles.posterWrapper)}
          onClick={() => setPlayVideo(true)}
        >
          <Image
            {...placeholderImage}
            aspectRatio={16 / 9}
            caption={false}
            credit={false}
            className={styles.poster}
          />
          <Icon
            name="button-play"
            aria-label={t("playVideoLabel")}
            className={clsx(styles.playIcon)}
          />
        </div>
      )}

      {playVideo && <VideoIframe url={url} aspectRatio={"16/9"} />}
    </ModuleWrapper>
  );
}
