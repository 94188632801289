import "./Button.props.css";
import "./Card.props.css";
import "./Color.props.css";
import "./Link.props.css";

export default {
  article: {
    defaultBodyFontSize: "clamp(1rem, 0.9643rem + 0.1786vw, 1.125rem)",
    preambleFontSize: "clamp(1.25rem, 1.2143rem + 0.1786vw, 1.375rem)",
    preambleFontWeight: "400",
    preambleLineHeight: "1.5",
    preambleColor: "",
    gap: "2.25rem",
  },
  base: {
    font: {
      family: "Libre Franklin, sans-serif",
      min: "1rem",
      prefered: "calc(0.5rem + 1vw)",
      max: "1.25rem",
      textItal: "1",
    },
    heading: {
      lineHeight: "1.125",
      marginBottom: "0.65em",
    },
    lineHeight: {
      min: 1.25,
      prefered: 1.5,
      max: 1.75,
    },
    width: "100%",
    height: "100%",
    padding: 0,
    margin: 0,
  },
  bodyBackground: "#E5E5E5",
  box: {
    background: "rgba(90,90,90, 5%)",
    borderRadius: "0.125rem",
    padding: "2rem",
  },
  boxNavigationLink: {
    color: "var(--color-surface)",
    background: "transparent",
    hoverColor: "var(--color-surface-inverse)",
    hoverBackground: "var(--color-theme-dark)",
    border: "2px solid var(--color-theme)",
    hoverBorder: "2px solid var(--color-theme-dark)",
    borderRadius: "2.5rem",
    padding: "0.75rem 1rem",
  },
  fileslistModule: {
    borderRadius: "",
    itemBackground: "var(--box-background)",
    linkColor: "var(--color-surface)",
    linkPadding: "1rem 2rem",
    listGap: "1rem",
    metaDataColor: "var(--color-neutral)",
    metaDataFontSize: "0.75rem",
  },
  flow: "2.5rem",
  focusBoxShadow: "0 0 0 3px var(--color-red)",
  footer: {
    background: "#404040",
    color: "var(--color-surface-inverse)",
  },
  form: {
    error: {
      color: "red",
    },
    input: {
      background: "#fff",
      border: "1px solid #e6e6e6",
      borderRadius: "2px",
      color: "var(--color-surface)",
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.55",
      padding: "0.75rem 0.875rem calc(0.75rem - 1px)",
    },
  },
  gallery: {
    arrowBackgroundColor: "rgba(255,255,255, 40%)",
    arrowColor: "var(--color-surface-inverse)",
    arrowDisabledBackgroundColor: "rgba(255,255,255, 40%)",
    arrowDisabledColor: "var(--color-neutral-tint-9)",
    autoplayControlColor: "var(--color-surface-inverse)",
    dotColor: "var(--color-red-tint-8)",
  },
  header: {
    background: "#ffffff",
    color: "var(--color-surface)",
  },
  image: {
    borderRadius: "0.125rem",
    captionFontSizeLg: "0.75rem",
    captionColor: "var(--color-neutral)",
    captionGap: "1rem",
    captionCompactGap: "0.25rem",
  },
  layoutPadding: "4rem",
  layoutPaddingMobile: "0.75rem",
  modularityAreaGap: "2.25rem",
  moduleWrapper: {
    headerIconBackground: "var(--color-theme)",
    headerRuleColor: "var(--color-divider)",
  },
  noticeModule: {
    contentFontSize: "1.125rem",
    padding: "2rem",
  },
  page: {
    gridColumnGap: "2rem",
    gridLayoutGap: "3.875rem",
    gridWrapperMaxWidth: "135.5rem",
    gridGap: "2rem",
    gridColumns: "12",
  },
  skipTo: {
    margin: "0.5rem",
    padding: "1rem",
    border: "",
    borderRadius: "0.25rem",
    boxShadow: "var(--focus-box-shadow)",
    fontFamily: "inherit",
    fontWeight: "600",
    fontSize: "",
    textDecoration: "",
    lineHeight: "",
    background: "var(--color-active)",
    color: "var(--color-surface-inverse)",
    outline: "",
    transition: "",
    zIndex: "",
  },
  table: {
    background: "var(--box-background)",
    cellPadding: "1rem",
    headBorderBottomColor: "var(--color-divider)",
    headFontSize: "1.125rem",
    cellBorderColor: "var(--color-divider)",
    cellFontSize: "1.125rem",
    headFontWeight: "600",
  },
  teaser: {
    innerGap: "2rem",
    titleFontWeight: "500",
  },
  width: {
    articleFull: "var(--page-grid-wrapper-max-width, 100%)",
    articleFullMin: "66.2%",
    articleNarrow: "74%",
    articleSidebar: "23.275%",
  },
};
