import {
  Teaser,
  TeaserContent,
  TeaserMedia,
  TeaserMeta,
  TeaserTitle,
} from "@whitespace/components";
import { useComponentSize } from "@whitespace/gatsby-hooks";
import {
  TermList,
  Time,
} from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import * as styles from "./ContentNodeTeaser.module.css";

WPDefaultContentNodeTeaser.propTypes = {
  className: PropTypes.string,
  contentNode: PropTypes.shape({
    content: PropTypes.node,
    date: PropTypes.string,
    excerpt: PropTypes.node,
    image: PropTypes.object,
    theme: PropTypes.string,
    title: PropTypes.node,
    url: PropTypes.string,
    taxonomies: PropTypes.arrayOf(PropTypes.object),
  }),
  dateFormat: PropTypes.objectOf(PropTypes.string),
  // styles: PropTypes.objectOf(PropTypes.string),
};

export default function WPDefaultContentNodeTeaser({
  className,
  contentNode,
  dateFormat = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  },
  ...restProps
}) {
  let componentRef = useRef(null);

  let { width: componentWidth } = useComponentSize(componentRef);

  const { date, excerpt, image, title, url, taxonomies } = contentNode;
  const isCompact = componentWidth <= 572;

  return (
    <div ref={componentRef}>
      <Teaser
        className={clsx(
          className,
          styles.teaser,
          isCompact && styles.isCompact
        )}
        link={{ url }}
        {...restProps}
      >
        <TeaserContent className={clsx(styles.content)}>
          <div className={clsx(styles.header, image && styles.headerWImage)}>
            <TeaserTitle className={clsx(className, styles.teaserTitle)}>
              {title}
            </TeaserTitle>
            {isCompact && image && (
              <TeaserMedia image={image} className={styles.image} />
            )}
          </div>

          {date && (
            <TeaserMeta>
              <Time
                capitalize={true}
                className={clsx(styles.date)}
                date={date}
                format={dateFormat}
              />
            </TeaserMeta>
          )}
          {excerpt && <p className={clsx(styles.excerpt)}>{excerpt}</p>}
          {taxonomies && taxonomies.length > 0 && (
            <TermList taxonomies={taxonomies} />
          )}
        </TeaserContent>
        {image && !isCompact && (
          <TeaserMedia image={image} className={styles.image} />
        )}
      </Teaser>
    </div>
  );
}
