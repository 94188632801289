import { withComponentDefaults, Link } from "@whitespace/components";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./ArticleTerms.module.css";

ArticleTerms.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default withComponentDefaults(ArticleTerms, "articleTerms");

function ArticleTerms({ styles = defaultStyles, className, ...restProps }) {
  let {
    contentNode: {
      newsTypes: { nodes: newsTypes = [] } = {},
      tags: { nodes: tags = [] } = {},
      shows: { nodes: shows = [] } = {},
    },
  } = usePageContext();
  const terms = [...tags, ...newsTypes, ...shows]?.map((term) => ({
    ...term,
    type: term.taxonomy?.node?.graphqlSingleName,
  }));

  if (!terms.length) {
    return null;
  }
  return (
    <div>
      <div className={clsx(styles.component, className)} {...restProps}>
        <ul className={clsx(styles.terms)}>
          {terms?.map((term, index) => (
            <li className={clsx(styles.term, styles[term.type])} key={index}>
              <Link className={clsx(styles.link)} to={term.uri}>
                {term.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
