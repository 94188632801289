import { PageGrid, PageGridItem } from "@whitespace/components";
import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import NewsTypes from "./NewsTypes";
import * as defaultStyles from "./TaxonomyIndex.module.css";
import Topics from "./Topics";

TaxonomyIndex.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function TaxonomyIndex({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <PageGrid>
        <PageGridItem
          className={clsx(
            styles.sectionNewsTypes,
            layout.componentWrapperPadding
          )}
        >
          <NewsTypes className={clsx(styles.newsTypes)} />
          <Topics className={clsx(styles.topics)} />
        </PageGridItem>
      </PageGrid>
    </div>
  );
}
