import { Article } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import PropTypes from "prop-types";
import React from "react";

import SEO from "../../../components/SEO";
import usePageLayout from "../hooks/usePageLayout";

SingleTemplate.propTypes = {
  pageContext: PropTypes.object,
};

export default function SingleTemplate({ pageContext }) {
  const pageLayout = usePageLayout();
  const {
    contentNode: { title, isFrontPage },
  } = pageContext;

  return (
    <>
      <SEO title={title} isFrontPage={isFrontPage} />
      <Article
        // displayMode="full"
        layoutMode={pageLayout}
      />
    </>
  );
}
