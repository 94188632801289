import { PageGrid, PageGridItem, Icon } from "@whitespace/components";
import FooterMenu from "@whitespace/gatsby-theme-wordpress-basic/src/components/FooterMenu";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import clsx from "clsx";
import { Link } from "gatsby";
import * as layout from "gatsby-theme-vansterpartiet/src/@whitespace/gatsby-theme-wordpress-basic/foundation/layout.module.css";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./Footer.module.css";
Footer.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function Footer({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const contactMenu = useMenu("HELP_MENU");
  const socialMediaLinks = useMenu("SOCIAL_MEDIA_LINKS");

  return (
    <footer className={clsx(styles.component, className)} {...restProps}>
      <div className={styles.linkContainer}>
        {contactMenu &&
          contactMenu.items.map((link, index) => (
            <Link key={index} to={link.url}>
              {link.label}
            </Link>
          ))}
      </div>

      <div className={styles.iconsContainer}>
        {socialMediaLinks?.items &&
          socialMediaLinks.items.map((item, index) => (
            <Link to={item.url} key={index}>
              <Icon color="white" size="32px" name={item.extra.icon} />
            </Link>
          ))}
      </div>

      <p className={styles.contactText}>
        © 2021 Vänsterpartiet • Vänsterpartiet • Box 12660, 112 93 Stockholm •
        Telefon: 08-654 08 20 • E-post: info@vansterpartiet.se
      </p>
      <PageGrid>
        <PageGridItem
          className={clsx(styles.wrapper, layout.componentWrapperPadding)}
        >
          <FooterMenu />
        </PageGridItem>
      </PageGrid>
    </footer>
  );
}
