import { css } from "@emotion/react";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { Icon, Button } from "@whitespace/components";
import { useComponentSize } from "@whitespace/gatsby-hooks";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import TextContent from "../../../../@whitespace/gatsby-theme-wordpress-basic/components/TextContent";

import * as defaultStyles from "./GiftModule.module.css";

GiftModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  module: PropTypes.shape({
    modGiftFields: PropTypes.shape({
      content: PropTypes.string,
      linkText: PropTypes.string,
    }),
  }),
};

export default function GiftModule({
  styles = defaultStyles,
  className,
  title,
  module: { modGiftFields: { content, pageLink } = {} },
  ...restProps
}) {
  const componentRef = useRef(null);

  let { width: componentWidth } = useComponentSize(componentRef);

  return (
    <ModuleWrapper
      title={
        title && (
          <>
            <Icon name={"heart"} />
            {title}
          </>
        )
      }
      {...restProps}
      css={css({})}
      styles={styles}
      className={clsx(className)}
      ref={componentRef}
    >
      <TextContent
        className={clsx(styles.content)}
        isCompact={componentWidth <= 972}
      >
        <HTML>{content}</HTML>
      </TextContent>
      {pageLink && (
        <Button to={pageLink.url} target={pageLink.target}>
          {pageLink.title}
        </Button>
      )}
    </ModuleWrapper>
  );
}
