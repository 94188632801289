import {
  Card,
  CardContent,
  CardMedia,
  CardMeta,
  CardTitle,
} from "@whitespace/components";
import { useComponentSize } from "@whitespace/gatsby-hooks";
import {
  TermList,
  Time,
} from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import ContentNodeBroadcastMedia from "../../../../../../../projects/rott.se/src/components/ContentNodeBroadcastMedia";
import ContentNodeBroadcastMeta from "../../../../../../../projects/rott.se/src/components/ContentNodeBroadcastMeta";

import * as styles from "./ContentNodeCard.module.css";

WPBroadcastCard.propTypes = {
  className: PropTypes.string,
  contentNode: PropTypes.shape({
    broadcastType: PropTypes.string,
    content: PropTypes.node,
    date: PropTypes.string,
    excerpt: PropTypes.node,
    image: PropTypes.object,
    postType: PropTypes.string,
    taxonomies: PropTypes.arrayOf(PropTypes.object),
    theme: PropTypes.string,
    title: PropTypes.node,
    url: PropTypes.string,
  }),
  dateFormat: PropTypes.objectOf(PropTypes.string),
  // styles: PropTypes.objectOf(PropTypes.string),
};

export default function WPBroadcastCard({
  contentNode,
  dateFormat = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  },
  className,
  ...restProps
}) {
  const { date, excerpt, image, title, url, taxonomies, broadcastType } =
    contentNode;
  let componentRef = useRef(null);

  let { width: componentWidth } = useComponentSize(componentRef);

  const isCompact = componentWidth <= 272;

  return (
    <div ref={componentRef}>
      <Card
        link={{ url }}
        className={clsx(
          styles.component,
          isCompact && styles.isCompact,
          className
        )}
        {...restProps}
      >
        <CardContent>
          <CardTitle className={clsx(styles.title)}>{title}</CardTitle>
          <ContentNodeBroadcastMeta type={broadcastType}>
            {date && (
              <CardMeta>
                <Time
                  capitalize={true}
                  className={clsx(styles.date)}
                  date={date}
                  format={dateFormat}
                />
              </CardMeta>
            )}
          </ContentNodeBroadcastMeta>

          {excerpt && <p className={clsx(styles.excerpt)}>{excerpt}</p>}
          {taxonomies && taxonomies.length > 0 && (
            <TermList taxonomies={taxonomies} />
          )}
        </CardContent>
        {image && (
          <ContentNodeBroadcastMedia
            className={styles.mediaWrapper}
            type={broadcastType}
          >
            <CardMedia image={image} />
          </ContentNodeBroadcastMedia>
        )}
      </Card>
    </div>
  );
}
