import { css } from "@emotion/react";
import { Button } from "@whitespace/components";
import clsx from "clsx";
import { kebabCase, camelCase } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./PostsModuleInlineListItem.module.css";

PostsModuleInlineListItem.propTypes = {
  className: PropTypes.string,
  dateFormat: PropTypes.objectOf(PropTypes.string),
  layout: PropTypes.string,
  item: PropTypes.shape({
    content: PropTypes.node,
    date: PropTypes.string,
    excerpt: PropTypes.node,
    image: PropTypes.object,
    theme: PropTypes.string,
    title: PropTypes.node,
    url: PropTypes.string,
  }),
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function PostsModuleInlineListItem({
  className,
  item,
  styles = defaultStyles,
  ...restProps
}) {
  const { title, url } = item;

  return (
    <Button
      url={url}
      className={clsx(styles.component, className)}
      {...restProps}
    >
      {title}
    </Button>
  );
}
