import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor";
import useMustacheData from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/useMustacheData";
import { rest } from "lodash";
import Mustache from "mustache";
import PropTypes from "prop-types";

PageContent.propTypes = {
  input: PropTypes.string,
  children: PropTypes.func.isRequired,
};

export default function PageContent({ input, children, ...restProps }) {
  let data = useMustacheData();
  let htmlString = Mustache.render(String(input || ""), data);
  let { processPageContent } = useHTMLProcessor();
  let { preamble, content } = processPageContent({
    content: htmlString,
    options: restProps,
  });
  return children({ preamble, content });
}
