import { Section } from "@jfrk/react-heading-levels";
import { ModularityArea } from "@municipio/gatsby-theme-basic/src/components";
import {
  PageGrid,
  PageGridItem,
  withComponentDefaults,
} from "@whitespace/components";
import ArticleBody from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleBody";
import ArticleChildPageNav from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleChildPageNav";
import ArticleTitle from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTitle";
import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import EventPageHeader from "../../../../components/EventPageHeader";
import { ConditionalWrapper } from "../../../../utils";

import * as defaultStyles from "./DefaultArticleLayout.module.css";

DefaultArticleLayout.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  content: PropTypes.objectOf(PropTypes.string),
};

export default withComponentDefaults(
  DefaultArticleLayout,
  "defaultArticleLayout"
);

function DefaultArticleLayout({
  className,
  styles = defaultStyles,
  content,
  ...restProps
}) {
  const { contentAreaBottom, contentArea, sliderArea, contentType } = content;

  const useHeroHeadingAsH1 = !![
    ...(contentAreaBottom?.modules || []),
    ...(contentArea?.modules || []),
    ...(sliderArea?.modules || []),
  ]
    .filter(Boolean)
    .find(({ module }) => module?.modHeroOptions?.useHeadingAsH1);

  const isEventPage = contentType === "event";

  return (
    <PageGrid className={clsx(styles.component, className)} {...restProps}>
      <PageGridItem>
        <div className={clsx(layout.component)}>
          <article>
            <ModularityArea
              area={sliderArea}
              className={clsx(styles.sliderArea)}
            />
            <div className={styles.articleContentContainer}>
              <ConditionalWrapper
                condition={useHeroHeadingAsH1}
                wrapper={(children) => <Section>{children}</Section>}
              >
                <div className={clsx(styles.header)}>
                  {!isEventPage && (
                    <ArticleTitle className={clsx(styles.title)} />
                  )}
                  {isEventPage && <EventPageHeader />}
                  <ArticleChildPageNav className={styles.childPages} />
                </div>
              </ConditionalWrapper>

              <Section>
                <ArticleBody className={styles.articleBody} />
                {!isEventPage && <ModularityArea area={contentArea} />}
              </Section>
            </div>
          </article>
          <Section>
            <ModularityArea area={contentAreaBottom} />
          </Section>
        </div>
      </PageGridItem>
    </PageGrid>
  );
}
