import { css } from "@emotion/react";
import ListPostsModuleItem from "@municipio/gatsby-theme-basic/src/components/modularity-modules/posts-modules/ListPostsModuleItem";
import PostsModuleFilterForm from "@municipio/gatsby-theme-basic/src/components/modularity-modules/PostsModuleFilterForm";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import PostsModuleHeader from "@municipio/gatsby-theme-basic/src/components/PostsModuleHeader";
import RuledList from "@municipio/gatsby-theme-basic/src/components/RuledList";
import clsx from "clsx";
import { kebabCase } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import Grid from "../../../Grid";

import * as defaultStyles from "./PostsModuleMixedLayout.module.css";

PostsModuleMixedLayout.propTypes = {
  className: PropTypes.string,
  itemComponent: PropTypes.elementType.isRequired,
  module: PropTypes.shape({
    modPostsDataDisplay: PropTypes.shape({
      postsFields: PropTypes.arrayOf(PropTypes.string),
      theme: PropTypes.string,
    }),
    modPostsDataSource: PropTypes.shape({
      postsDataPostType: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  normalizedItems: PropTypes.arrayOf(PropTypes.object),
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
};

export default function PostsModuleMixedLayout({
  className,
  itemComponent: PrimaryItem,
  module,
  normalizedItems,
  styles = defaultStyles,
  title,
  ...restProps
}) {
  const {
    modPostsDataDisplay: { theme },
    modPostsDataSource: { postsDataPostType },
  } = module;

  const postType = postsDataPostType?.name;
  const isBroadcast = postType == "broadcast";
  const isEvent = postType == "event";

  return (
    <ModuleWrapper
      title={title}
      {...restProps}
      className={clsx(styles.component, theme, className)}
      components={{
        ModuleWrapperHeader: PostsModuleHeader,
      }}
      css={css({
        "--card-background": theme
          ? `var(--brand-color-${kebabCase(theme)})`
          : "transparent",
        "--card-hover-background": theme
          ? `var(--brand-color-${kebabCase(theme)})`
          : "transparent",
        "--list-rule-color": theme
          ? `var(--brand-color-${kebabCase(theme)})`
          : "var(--color-divider)",
        "--module-wrapper-title-rule-color": theme
          ? `var(--brand-color-${kebabCase(theme)})`
          : "var(--color-divider)",
        "--card-color": theme
          ? `var(--brand-color-${kebabCase(theme)}-text)`
          : null,
        "--card-hover-color": theme
          ? `var(--brand-color-${kebabCase(theme)}-text)`
          : null,
        "--card-meta-color": theme
          ? `var(--brand-color-${kebabCase(theme)}-text)`
          : null,
      })}
    >
      <PostsModuleFilterForm className={clsx(styles.filterForm)} />
      <Grid
        className={clsx(styles.list, styles[postType])}
        itemWrapperCSS={({ index, columns }) => {
          return css({
            "grid-column":
              isBroadcast &&
              index == 1 &&
              `${columns?.length - 1 >= 2 ? 2 : 0}/-1`,
          });
        }}
      >
        {({ columns = [] }) => {
          let primaryItemCount = isBroadcast
            ? 1
            : normalizedItems.length > columns.length
            ? Math.max(1, columns.length - 1)
            : normalizedItems.length;
          let primaryItems = normalizedItems.slice(0, primaryItemCount);

          let secondaryItems = normalizedItems.slice(primaryItemCount);

          return [
            ...primaryItems.map((item, index) => {
              return (
                <PrimaryItem
                  key={index}
                  className={clsx(
                    styles.item,
                    isBroadcast && styles.noRule,
                    isEvent && styles.eventList
                  )}
                  item={item}
                />
              );
            }),
            secondaryItems.length > 0 && (
              <RuledList
                className={clsx(
                  styles.secondaryList,
                  isBroadcast &&
                    columns?.length > 1 &&
                    styles.secondaryListColumn
                )}
                noRule={isBroadcast}
                key="secondary"
              >
                {secondaryItems.map((item, index) => {
                  return (
                    <ListPostsModuleItem
                      item={{
                        ...item,
                        image: isBroadcast ? item.image : null,
                        excerpt: null,
                      }}
                      className={clsx(styles.secondaryItem)}
                      key={index}
                    />
                  );
                })}
              </RuledList>
            ),
          ];
        }}
      </Grid>
    </ModuleWrapper>
  );
}
