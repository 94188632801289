import { css } from "@emotion/react";
import Box from "@municipio/gatsby-theme-basic/src/components/Box";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { useComponentSize } from "@whitespace/gatsby-hooks";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import { kebabCase } from "lodash";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import TextContent from "../../../../@whitespace/gatsby-theme-wordpress-basic/components/TextContent";

import * as defaultStyles from "./TextModule.module.css";

TextModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  module: PropTypes.shape({
    content: PropTypes.string,
    contentMedia: PropTypes.arrayOf(PropTypes.object),
    modTextOptions: PropTypes.shape({
      hideBoxFrame: PropTypes.bool,
      theme: PropTypes.string,
    }),
  }),
};

export default function TextModule({
  styles = defaultStyles,
  className,
  title,
  module: {
    content,
    contentMedia,
    modTextOptions: { hideBoxFrame = false, theme, type } = {},
  },
  // colorScheme,
  ...restProps
}) {
  const Wrapper = hideBoxFrame ? "div" : Box;

  const componentRef = useRef(null);

  let { width: componentWidth } = useComponentSize(componentRef);

  return (
    <ModuleWrapper
      as={Wrapper}
      title={title && <div>{title}</div>}
      {...restProps}
      css={css({
        "--box-background": theme
          ? `var(--brand-color-${kebabCase(theme)})`
          : "",
        "--box-color":
          type == "info" && !theme
            ? "var(--color-theme)"
            : theme && type == "default"
            ? `var(--brand-color-${kebabCase(theme)}-text)`
            : "",
        "--box-font-size": "var(--text-module-boxed-font-size, 0.875rem)",
      })}
      styles={styles}
      className={clsx(className, type == "info" && styles.info)}
      ref={componentRef}
    >
      <TextContent
        className={clsx(styles.content)}
        isCompact={componentWidth <= 972}
      >
        <HTML contentMedia={contentMedia}>{content}</HTML>
      </TextContent>
    </ModuleWrapper>
  );
}
