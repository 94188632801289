import { withComponentDefaults } from "@whitespace/components";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import { upperFirst } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./Article.module.css";
import * as layoutComponents from "./articleLayouts";

Article.propTypes = {
  className: PropTypes.string,
  layoutMode: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default withComponentDefaults(Article, "article");

const getComponentForLayout = (layout) => {
  let layoutComponent = layout && `${upperFirst(layout)}ArticleLayout`;

  // eslint-disable-next-line import/namespace
  return layoutComponent && layoutComponents[layoutComponent];
};

function Article({
  layoutMode = "default",
  styles = defaultStyles,
  className,
  ...restProps
}) {
  let {
    contentNode: {
      isFrontPage,
      contentArea,
      contentAreaBottom,
      contentType: {
        node: { name: contentTypeName },
      },
      rightSidebar,
      sliderArea,
    },
    // isPreview,
  } = usePageContext();
  const isEventPage = contentTypeName === "event";

  const ArticleLayout = isEventPage
    ? getComponentForLayout("split")
    : getComponentForLayout(layoutMode);

  return (
    <ArticleLayout
      className={clsx(styles.component, className)}
      content={{
        contentType: contentTypeName,
        isFrontPage,
        contentArea,
        contentAreaBottom,
        rightSidebar,
        sliderArea,
      }}
      {...restProps}
    />
  );
}
