import { useID, Icon } from "@whitespace/components";
import { visuallyHidden } from "@whitespace/components/dist/utils/styles.module.css";
import * as styles from "@whitespace/gatsby-plugin-search/src/components/search-form-fields/SearchFormQueryField.module.css";
import { useSearchSettings } from "@whitespace/gatsby-plugin-search/src/hooks";
import cx from "classnames";
import { Field } from "formik";
import PropTypes from "prop-types";
import React from "react";

SearchFormQueryField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.node,
  submitLabel: PropTypes.node,
};

export default function SearchFormQueryField({
  id,
  label,
  submitLabel,
  ...restProps
}) {
  const { searchPlaceholderText, searchLabelText, searchButtonText } =
    useSearchSettings();
  label = label || searchLabelText || "Search query";
  submitLabel = submitLabel || searchButtonText || "Search";
  const generateID = useID();
  return (
    <div className={styles.wrapper}>
      <label
        className={cx(styles.visuallyHidden, visuallyHidden)}
        htmlFor={id || generateID("search-query")}
      >
        {label}
      </label>
      <Field
        type="search"
        name="query"
        id={id || generateID("search-query")}
        className={cx(styles.input)}
        placeholder={searchPlaceholderText}
        {...restProps}
      />
      <button type="submit" className={styles.submit} aria-label={submitLabel}>
        <div>
          <Icon name="search" className={styles.icon} />
        </div>
      </button>
    </div>
  );
}
