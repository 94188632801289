import { Section } from "@jfrk/react-heading-levels";
import { ModularityArea } from "@municipio/gatsby-theme-basic/src/components";
import {
  withComponentDefaults,
  PageGrid,
  PageGridItem,
} from "@whitespace/components";
import ArticleFeaturedImage from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleFeaturedImage";
import ArticleMeta from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleMeta";
import ArticlePublishDate from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticlePublishDate";
import ArticleTitle from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTitle";
import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import ArticleRelatedPosts from "../../../../components/ArticleRelatedPosts";
import ArticleBody from "../ArticleBody";
import ArticleSidebar from "../ArticleSidebar";
import ArticleTerms from "../ArticleTerms";

import * as defaultStyles from "./DebateArticleLayout.module.css";

DebateArticleLayout.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  content: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
};

export default withComponentDefaults(
  DebateArticleLayout,
  "DebateArticleLayout"
);

function DebateArticleLayout({
  className,
  styles = defaultStyles,
  content,
  ...restProps
}) {
  const {
    contentType,
    contentArea,
    contentAreaBottom,
    isFrontPage,
    rightSidebar,
    sliderArea,
  } = content;

  return (
    <PageGrid className={clsx(styles.component, className)}>
      <div className={clsx(styles.backDrop)} />
      <PageGridItem>
        <div className={clsx(layout.component)}>
          <article>
            <ModularityArea
              area={sliderArea}
              className={clsx(styles.sliderArea)}
            />
            <div className={styles.articleContentContainer}>
              <div className={clsx(styles.wrapper)}>
                <div
                  className={clsx(
                    styles.header,
                    layout.component,
                    layout.componentWidthArticleFull
                  )}
                >
                  <ArticleTerms />
                  <ArticleTitle className={clsx(styles.title)} />
                  <ArticlePublishDate className={styles.publishedDate} />
                </div>
              </div>
              <Section>
                <div className={clsx(styles.wrapper)}>
                  <div
                    className={clsx(
                      styles.content,
                      layout.component,
                      layout.componentWidthArticleFull
                    )}
                  >
                    <ArticleFeaturedImage className={styles.featuredImage} />
                    <ArticleBody
                      className={clsx(styles.articleBody)}
                      hideArticleImage={true}
                    />
                    <ModularityArea area={contentArea} />

                    <footer className={clsx(styles.footer)}>
                      <ArticleMeta />
                    </footer>

                    <ModularityArea
                      area={contentAreaBottom}
                      className={clsx(styles.contentAreaBottom)}
                    />
                  </div>
                  <ArticleSidebar
                    className={clsx(
                      layout.component,
                      layout.componentWidthArticleSidebar
                    )}
                  >
                    <ModularityArea area={rightSidebar} rightSidebar={true} />
                  </ArticleSidebar>
                </div>
                <div className={clsx(styles.wrapper)}>
                  <div
                    className={clsx(
                      layout.component,
                      layout.componentWidthArticleFull
                    )}
                  >
                    <ArticleRelatedPosts
                      className={clsx(styles.articleRelatedPosts)}
                    />
                  </div>
                </div>
              </Section>
            </div>
          </article>
        </div>
      </PageGridItem>
    </PageGrid>
  );
}
