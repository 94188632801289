import ArticleFeaturedImage from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleFeaturedImage";
import PageContent from "@whitespace/gatsby-theme-wordpress-basic/src/components/PageContent";
import WPBlocks from "@whitespace/gatsby-theme-wordpress-basic/src/components/WPBlocks";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import EventInfo from "../../../components/EventInfo";

import * as defaultStyles from "./ArticleBody.module.css";
import TextContent from "./TextContent";

ArticleBody.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  hideArticleImage: PropTypes.bool,
};

export default function ArticleBody({
  styles = defaultStyles,
  className,
  hideArticleImage = false,
  ...restProps
}) {
  let {
    contentNode: {
      content: contentHTML,
      contentMedia,
      blocksJSON,
      acfGroupEvent,
      contentType: {
        node: { name: contentTypeName },
      },
    },
  } = usePageContext();

  const isEventPage = contentTypeName === "event";

  return (
    <TextContent className={clsx(styles.component, className)} {...restProps}>
      {blocksJSON ? (
        <>
          <WPBlocks
            blocks={JSON.parse(blocksJSON)}
            contentMedia={contentMedia}
          />
        </>
      ) : (
        <>
          {!hideArticleImage && <ArticleFeaturedImage />}
          <PageContent input={contentHTML} contentMedia={contentMedia}>
            {({ preamble, content }) => (
              <>
                {preamble && (
                  <div
                    className={clsx(styles.preamble, {
                      [styles.eventStylePreamble]: isEventPage,
                    })}
                  >
                    {preamble}
                  </div>
                )}
                {isEventPage && <EventInfo info={acfGroupEvent} />}
                {content}
              </>
            )}
          </PageContent>
        </>
      )}
    </TextContent>
  );
}
