import { H, Section } from "@jfrk/react-heading-levels";
import { Link } from "@whitespace/components";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./ArticleRelatedPosts.module.css";

ArticleRelatedPosts.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function ArticleRelatedPosts({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { t } = useTranslation();
  const {
    contentNode: { relatedPosts: { nodes: relatedPosts = [] } = {} },
  } = usePageContext();

  if (!relatedPosts.length) return null;

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <div className={styles.inner}>
        <Section>
          <H className={clsx(styles.title, "h4")}>{t("Related posts")}</H>
          <ul className={styles.list}>
            {relatedPosts.map((post, index) => (
              <li className={styles.item} key={index}>
                <Link className={styles.link} to={post.uri}>
                  {post.title}
                </Link>
              </li>
            ))}
          </ul>
        </Section>
      </div>
    </div>
  );
}
