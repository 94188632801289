import { useStaticQuery, graphql } from "gatsby";

export default function getCustomSiteMetaData() {
  return useStaticQuery(graphql`
    query SiteMeta {
      wp {
        acfOptionsThemeOptions {
          customSiteMeta {
            title
            description
            image {
              base64: base64Uri
              src: sourceUrl(size: THUMBNAIL)
              srcSet: srcSet(size: THUMBNAIL)
              width(size: THUMBNAIL)
              height(size: THUMBNAIL)
              alt: altText
              caption
              credit
            }
          }
        }
      }
    }
  `).wp.acfOptionsThemeOptions;
}
