import {
  Icon,
  Teaser,
  TeaserContent,
  TeaserMeta,
  TeaserTitle,
} from "@whitespace/components";
import { useComponentSize } from "@whitespace/gatsby-hooks";
import {
  TermList,
  Time,
} from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import Image from "@whitespace/gatsby-theme-wordpress-basic/src/components/Image";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import ContentNodeBroadcastMedia from "../../../../../../../projects/rott.se/src/components/ContentNodeBroadcastMedia";
import ContentNodeBroadcastMeta from "../../../../../../../projects/rott.se/src/components/ContentNodeBroadcastMeta";

import * as styles from "./WPBroadcastTeaser.module.css";

WPBroadcastTeaser.propTypes = {
  className: PropTypes.string,
  contentNode: PropTypes.shape({
    broadcastType: PropTypes.string,
    content: PropTypes.node,
    date: PropTypes.string,
    excerpt: PropTypes.node,
    image: PropTypes.object,
    theme: PropTypes.string,
    title: PropTypes.node,
    show: PropTypes.object,
    url: PropTypes.string,
    taxonomies: PropTypes.arrayOf(PropTypes.object),
  }),
  dateFormat: PropTypes.objectOf(PropTypes.string),
  // styles: PropTypes.objectOf(PropTypes.string),
};

export default function WPBroadcastTeaser({
  className,
  contentNode,
  dateFormat = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  },
  ...restProps
}) {
  let componentRef = useRef(null);

  let { width: componentWidth } = useComponentSize(componentRef);

  const { date, excerpt, image, title, show, url, taxonomies, broadcastType } =
    contentNode;
  const isCompact = componentWidth <= 572;

  return (
    <div ref={componentRef}>
      <Teaser
        className={clsx(
          className,
          styles.teaser,
          isCompact && styles.isCompact
        )}
        link={{ url }}
        {...restProps}
      >
        <TeaserContent className={clsx(styles.content)}>
          <TeaserTitle className={clsx(className, styles.teaserTitle)}>
            {show && <strong>{show.name}: </strong>}
            {title}
          </TeaserTitle>
          <ContentNodeBroadcastMeta type={broadcastType}>
            {date && (
              <TeaserMeta>
                <Time
                  capitalize={true}
                  className={clsx(styles.date)}
                  date={date}
                  format={dateFormat}
                />
              </TeaserMeta>
            )}
          </ContentNodeBroadcastMeta>
          {excerpt && <p className={clsx(styles.excerpt)}>{excerpt}</p>}
          {taxonomies && taxonomies.length > 0 && (
            <TermList taxonomies={taxonomies} />
          )}
        </TeaserContent>
        {image && (
          <ContentNodeBroadcastMedia type={broadcastType}>
            <Image {...image} aspectRatio={16 / 9} className={styles.image} />
          </ContentNodeBroadcastMedia>
        )}
      </Teaser>
    </div>
  );
}
