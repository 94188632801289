import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { Icon, Button, FormInputField } from "@whitespace/components";
import { useComponentSize } from "@whitespace/gatsby-hooks";
import clsx from "clsx";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import * as defaultStyles from "./NewsletterModule.module.css";

NewsletterModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  module: PropTypes.any,
};

export default function NewsletterModule({
  styles = defaultStyles,
  className,
  title,
  module: { modNewsletterOptions: { text } = {} },
  ...restProps
}) {
  const { t } = useTranslation();

  const action = "/";
  const componentRef = useRef(null);

  let { width: componentWidth } = useComponentSize(componentRef);

  const isCompact = componentWidth <= 340;

  return (
    <ModuleWrapper
      as={"div"}
      title={
        <>
          <Icon name="newsletter" className={styles.titleIcon} />
          {title}
        </>
      }
      styles={styles}
      className={clsx(styles.component, isCompact && styles.isCompact)}
      {...restProps}
    >
      <div ref={componentRef}>
        <p className={styles.text}>{text}</p>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={yup.object().shape({
            email: yup
              .string()
              .email(t("Please enter a valid email address"))
              .required(t("Please enter your email address")),
          })}
          onSubmit={async (values, actions) => {
            // @TODO: Add endpoint here
            actions.setSubmitting(false);

            let success = true;
            if (success) {
              actions.setStatus({ success: true });
            } else {
              actions.setStatus({ failure: true });
            }
          }}
        >
          {({
            isSubmitting,
            handleSubmit,
            status: { success, failure } = {},
          }) =>
            success ? (
              <div className={styles.success}>
                {t("modNewsletterSignupSuccess")}
              </div>
            ) : failure ? (
              <div className={styles.failure}>
                {t("modNewsletterSignupFailure")}
              </div>
            ) : (
              <form method="post" action={action} className={styles.form}>
                <FormInputField
                  name={"email"}
                  label={t("Email address")}
                  type={"email"}
                  inputProps={{
                    placeholder: t("Email address"),
                  }}
                  description={t("Enter your email address")}
                  required={true}
                  className={styles.formInput}
                />
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  {t("Subscribe")}
                </Button>
              </form>
            )
          }
        </Formik>
      </div>
    </ModuleWrapper>
  );
}
